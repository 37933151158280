<template>
  <section class='d-flex'>
    <v-progress-circular v-if="loadingUser" class='my-auto mx-auto' :size='150' :width='10' color='primary' indeterminate></v-progress-circular>
    <article v-if="!loadingUser" class='d-flex flex-column align-center mx-auto'>
      <h1 class='text-center text-gold'>{{ $t('Config') }}</h1>
      <div class='toggleOption d-flex justify-space-between align-center mt-15 px-2' v-for='(toggleOption, i) in toggleOptions' :key='i'>
        <label class='title'>{{ $t(toggleOption.name) }}</label>
        <div class="toggle toggle--daynight">
          <input type="checkbox" id="toggle--daynight" class="toggle--checkbox" v-model='toggleOption.value' 
            @change="toggleConfig(i)">
          <label class="toggle--btn" for="toggle--daynight"><span class="toggle--feature"></span></label>
        </div>
      </div>  
      <div v-if="!toggleOptions[0].value" class='colorOption d-flex justify-space-between align-start mt-5 px-2'>
        <label class='title'>{{ $t('Color') }}</label>
        <v-select dense outlined :items="colors.items" color='primary' v-model="colors.value" 
          @change="selectColor()">
          <template v-slot:selection="{ item }" >
            <div class='selected-color' :style="{ height: '25px', width: '35px', backgroundColor: item.hex }"></div>
          </template>
          <template v-slot:item="{ item }">
            <div :style="{ height: '25px', width: '35px', backgroundColor: item.hex }"></div>
          </template>
        </v-select>
      </div>  
    </article>
  </section>
</template>
<script>
import { showSnackBar } from '@/services/snackbar'

export default {
  name: 'Settings',
  data: () => ({
    user: null,
    loadingUser: false,
    toggleOptions: [
      {
        name: 'Random Colors',
        value: false
      },
    ],
    colors: {
      value: '',
      items: [ 
        { name: 'purple',    hex: '#6800FF'}, 
        { name: 'indigo',    hex: '#000DC5'}, 
        { name: 'lightblue', hex: '#00B2F8'}, 
        { name: 'green',     hex: '#41EC41'}, 
        { name: 'yellow',    hex: '#FFCB00'}, 
        { name: 'orange',    hex: '#FF8C00'}, 
        { name: 'red',       hex: '#F60000'}
      ]
    },
    storeWatchers: {
      user: null,
    } 
  }),
  created () {
    this.user = this.$store.getters['user/user']
    this.watchAndSetUser()

    if (!this.user) {
      this.loadingUser = true
      return
    }

    this.toggleOptions[0].value = this.user?.settings.color === 'random'
  },
  beforeDestroy () {
    this.storeWatchers.user()
  },
  methods: {
    watchAndSetUser () {
      this.storeWatchers.user = this.$store.watch(
        state => state.user.user,
        changedUser => { 
          this.user = changedUser
          this.toggleOptions[0].value = changedUser.settings.color === 'random'
          this.loadingUser = false
        },
        { deep: true } 
      )
    },
    toggleConfig (index) {
      this.updateUser({ 
        settings: { 
          color: this.toggleOptions[index].value ? 'random' : 'purple',
        }
      })
    },
    selectColor () {
      this.updateUser({
        settings: { 
          color: this.colors.value.name
        }
      })
    },
    updateUser(userData) {
      userData.settings = { ...this.user.settings, ...userData.settings }

      this.$store.dispatch('user/updateUser', userData)
      .then(() => 
          showSnackBar({
            message: 'Your settings has been successfully updated!',
              config: {
                color: '#13b719',
                timeout: 3500
              }
          })
      )
      .catch(err => console.log(err))
    } 
  }
}
</script>

<style lang='scss' scoped>
  @import '@/assets/scss/_classes.scss';

  section {
    min-height: 475px;

    article {
      width: 100%;
      max-width: 900px;
  
      h1 {
        font-size: 30px;
        font-family: 'Pacifico', cursive; 
        text-shadow: 1px 1px 1px gray;
      }

      label.title { font-size: 28px !important; }

      .toggleOption, .colorOption { width: 100%; }

      .colorOption {
        label.title { transform: translateY(4px); }

        .selected-color { margin-left: 26px; }

        ::v-deep {
          .v-input {

            &.v-text-field--outlined.v-input--is-focused fieldset {
              border: 2px solid currentColor !important;
            }

            .v-input__control {
              .v-input__slot { 
                width: 115px;
                margin-left: auto;

                fieldset { border: 2px solid black; }

                .v-select__slot { 
                  .v-input__append-inner {
                    .v-input__icon--append {
                      i {
                        color: currentColor !important;
                      }
                    }
                  } 
                }
              }
            }
          }
        }
      }
    }
  }

  ::v-deep {
    .v-list-item {
      display: flex;
      justify-content: center;
      color: white !important;
    }
  }
       
</style>